var stickyHeader = true;
var countUpStats = true;
var countUpStatsDuration = 3000;

var lualBodyClasses = ["light-up-a-life"];
var visualAppealsLoadMore = true;

// var flyoutNav = false;
// var fullscreenNav = false;
// var inlineNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// see https://fresco-docs.netlify.com/#/carousel/background-video
// ---------------------------------------------------------------
// var carouselBackgroundVideoYouTubeID = '';

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// -----------------------------------------------------------------
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link'];
// var feedItemsCarouselBreakpoint = 576;

// see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// --------------------------------------------------------------------
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// Make sure to set a card layout prefab for:
// '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'
// --------------------------------------------------------------------------------
// var sidebarCards = false;

$(document).ready(function () {

  // Header -----------------
  // Initial call to handleScreenSize
  handleMenu();
  // Attach the handleScreenSize function to the window resize event
  $(window).on("resize", handleMenu);

  // Footer -----------------
  // Newsletter adjustment
  adjustFooterNewsletter();
  // General layout for footer
  adjustFooterContent();

  // Homepage ---------------
  // Main carousel banner
  adjustMainCarousel();
  // CTA section - dual version
  adjustCTADual();
  // CTA section - mixed up
  adjustCTAContent();
  // Support please
  adjustSupport();
  // Fundraise please
  adjustFundraise();
  // Donation please
  adjustQuickGiving();
  // Stats section
  adjustImpactStats();
  // Feed for stories
  adjustStoriesFeed();
  // Feed for events
  adjustEventsFeed();

  // Internal -------------
  // Move sub banner descriptions out to below with the content
  if ($(".listingIntro").length) {
    $(".listingIntro").prependTo(".pageWrapper > section > .contentBlockWrapper > .contentBlock");
    $(".listingIntro").prependTo(".appealListingPage .donorsListWrapper");
    $(".listingIntro").prependTo(".postCreate > .contentBlockWrapper > .contentBlock");
  }
  // Move sub banner title out if carousel multiple is used
  if ($(".carouselUsed").length) {
    $(".carouselUsed .headerText").prependTo(".pageWrapper > section > .contentBlockWrapper > .contentBlock");
    $(".carouselUsed .headerText").prependTo(".appealListingPage .donorsListWrapper");
    $(".carouselUsed .headerText").prependTo(".postCreate > .contentBlockWrapper > .contentBlock");
  }
  // Check if the div is "empty" (ignoring whitespace) - hide if yes
  if ($(".headerText").children().length === 0 || $(".headerText").children().text().trim() === "") {
    $(".headerText").hide();
  }
  if ($(".listingIntro").children().length === 0 || $(".listingIntro").children().text().trim() === "") {
    $(".listingIntro").parent().parent().hide();
  }
  // Check if any page title is missing
  const pageBanner = $("body:not(.homepage) .pageWrapper .headerWrapper .headerText");
  if (!(pageBanner.find(".title").length > 0)) {
    pageBanner.hide();
  }
  // Move share wrapper outside of post block content
  if ($(".shareWrapper").length) {
    $(".shareWrapper").insertBefore(".contentBlockWrapper > .contentBlock .postFeedWrapper");
  }
  // Move comments outside of post content
  if ($(".postContent .postComments").length) {
    $(".postContent .postComments").insertBefore(".contentBlockWrapper > .contentBlock .postFeedWrapper");
  }
  // Move appeal widget outside of post content
  if ($(".appealInteractionsWrapper").length) {
    $(".appealInteractionsWrapper").insertBefore(".contentBlockWrapper > .contentBlock .postFeedWrapper");
  }
  // Blockquotes get additional div wrapper
  if ($("blockquote").length) {
    $("blockquote").wrapInner('<div class="quote-wrapper"></div>');
  }

});

function adjustMainCarousel() {
  // setup video
  var video = "https://oliver-ssl-assets.s3.amazonaws.com/charity_websites/dorsetsomersetairambulance.mp4";
  var source = '<source src="'+ video +'" type="video/mp4">';
  var options = 'autoplay loop="loop" muted="muted" volume="0"';
  var newElement = $('<video ' + options + ' >' + source + '</video>');
  // reference elements
  const container = $("body.homepage #carouselSlides");
  // add the video element
  newElement.insertBefore(container);
  // modify first slide button text
  container.find("li:first-of-type .carouselSlideReadMore").html("Donate");
  // modify slide title last two texts as custom
  container.find(".carouselSlideHeading a").each(function () {
    // Get the text of the current element
    let text = $(this).text().trim();
    // Split the text into words
    let words = text.split(' ');
    // Check if there are at least two words
    if (words.length > 1) {
      // Extract the last two words
      let lastTwoWords = words.splice(-2).join(' ');
      // Join the remaining words and add the span around the last two words
      let updatedText = `${words.join(' ')} <span class="text-stroke-white">${lastTwoWords}</span>`;
      // Update the current element's HTML with the modified text
      $(this).html(updatedText);
    }
  });
}

function handleMenu() {
  // move menu to between logo and main CTA
  if ($(window).width() > 1329) {
    // Check if the menu's parent is the header content or not
    if (!$("#menuMain").parent().is($("#pageHeader .headerContent"))) {
      // Else append the menu to the header content
      $("#menuMain").appendTo("#pageHeader .headerContent");
    }
  } else {
    // Check if the menu is inserted after or not
    if (!$("#pageHeader .headerContent").next().is($("#menuMain"))) {
      // Else insert the new element after the target element
      $("#menuMain").insertAfter("#pageHeader .headerContent");
    }
  }
}

function adjustEventsFeed() {
  // initiate feed
  initCarousel(
    "homeFeedBox2", 
    "See all events", 
    "/Pages/Events/category/our-events", 
    "Slide for more events", false, 2, false
  );
  // Reference the elements
  const container = $(".homefeaturecategory-oureventsfeed");
  const carousel = $(".homeFeedBox2");
  // Wrap div classes
  container.wrapInner('<div class="tagline-wrapper"></div>');
  container.find(".tagline-wrapper p:last-of-type").wrap('<div class="cta-wrapper"></div>')
  container.find(".cta-wrapper").insertAfter(container.find(".tagline-wrapper"));
  container.wrapInner('<div class="container-wrapper"></div>');
  // Move feed into section
  carousel.appendTo(container);
}

function adjustStoriesFeed() {
  // initiate feed
  initCarousel(
    "homeFeedBox1", 
    "See all stories", 
    "/blogs/patient-stories", 
    "Slide for more stories", true, 1
  );
  // Reference the elements
  const container = $(".homefeaturecategory-patientstoriesfeed");
  const carousel = $(".homeFeedBox1");
  // Wrap div classes
  container.wrapInner('<div class="cta-wrapper"></div>');
  carousel.prependTo(container);
  container.find(".navigator").insertAfter(carousel);
  container.find(".cta-wrapper").appendTo(container.find(".navigator"));
}

function adjustImpactStats() {
  // Reference the elements
  const container = $(".homefeaturecategory-homeboximpactstats");
  // Wrap div classes
  container.find("img:first-of-type").wrap('<div class="image-wrapper"></div>');
  container.find(".homeImpactWrapper > h2").wrap('<div class="title-wrapper"></div>');
}

function adjustQuickGiving() {
  // Reference the elements
  const container = $(".homefeaturecategory-quickgivingpanel");
  // Wrap div classes
  container.find("img:first-of-type").wrap('<div class="image-wrapper"></div>');
  container.wrapInner('<div class="tagline-wrapper"></div>');
  container.wrapInner('<div class="content-wrapper"></div>');
  // Reposition the elements
  container.find(".mceNonEditable").insertBefore(container.find(".tagline-wrapper"));
  container.find(".image-wrapper").insertBefore(container.find(".content-wrapper"));
  container.find(".quickGivingPanel .selectDonationAmount").wrapInner('<div class="donateWrapper"></div>');
  container.find(".quickGivingPanel .selectDonationAmount").append('<div class="regularPaymentGroup"></div>');
  container.find(".donateWrapper > span:first-child").insertBefore(container.find(".donateWrapper"));
  container.find(".regularPayment").appendTo(container.find(".regularPaymentGroup"));
  container.find(".regularPaymentLabel").appendTo(container.find(".regularPaymentGroup"));
}

function adjustFundraise() {
  // Reference the elements
  const container = $(".homefeaturecategory-fundraisecontainer");
  var ctaWrapper = $('<div class="cta-wrapper"></div>');
  // Wrap div classes
  container.find("img:first-of-type").wrap('<div class="image-wrapper"></div>');
  container.wrapInner('<div class="tagline-wrapper"></div>');
  container.wrapInner('<div class="content-wrapper"></div>');
  ctaWrapper.insertAfter(container.find(".tagline-wrapper"));
  container.find(".image-wrapper").insertBefore(container.find(".content-wrapper"));
  container.find("ul").appendTo(container.find(".cta-wrapper"));
}

function adjustSupport() {
  // Reference the elements
  const container = $(".homefeaturecategory-supportcontainer");
  var ctaWrapper = $('<div class="cta-wrapper"></div>');
  // Wrap div classes
  container.find("img:first-of-type").wrap('<div class="image-wrapper"></div>');
  container.wrapInner('<div class="tagline-wrapper"></div>');
  container.wrapInner('<div class="content-wrapper"></div>');
  ctaWrapper.insertAfter(container.find(".tagline-wrapper"));
  container.find(".image-wrapper").insertBefore(container.find(".content-wrapper"));
  container.find("ul").appendTo(container.find(".cta-wrapper"));
}

function adjustCTADual() {
  // Reference the elements
  const container = $(".homefeaturecategory-ctadualcontainer");
  const boxOne = $(".homefeaturecategory-ctadualboxone");
  const boxTwo = $(".homefeaturecategory-ctadualboxtwo");
  // Wrap the elements with a div element
  container.wrapInner('<div class="title-wrapper"></div>');
  boxOne.wrapInner('<div class="cta-wrapper"></div>');
  boxTwo.wrapInner('<div class="cta-wrapper"></div>');
  // Wrap new wrapper after container
  container.append('<div class="content-wrapper"></div>');
  // Wrap new wrapper before the two boxes
  boxOne.prepend('<div class="image-wrapper"></div>');
  boxTwo.prepend('<div class="image-wrapper"></div>');
  // Move image into the new wrapper
  boxOne.find("img").appendTo(boxOne.find(".image-wrapper"));
  boxTwo.find("img").appendTo(boxTwo.find(".image-wrapper"));
  // Move the other boxes inside
  boxOne.appendTo(container.find(".content-wrapper"));
  boxTwo.appendTo(container.find(".content-wrapper"));
  // finally wrap the real container wrapper
  container.wrapInner('<div class="container-wrapper"></div>');
}

function adjustCTAContent() {
  // Reference the elements
  const container = $(".homefeaturecategory-ctacontentcontainer");
  const boxOne = $(".homefeaturecategory-ctacontentboxone");
  const boxTwo = $(".homefeaturecategory-ctacontentboxtwo");
  // Wrap the elements with a div element
  container.wrapInner('<div class="image-wrapper"></div>');
  boxOne.wrapInner('<div class="boxone-wrapper"></div>');
  boxTwo.wrapInner('<div class="boxtwo-wrapper"></div>');
  // Wrap new wrapper after container
  container.append('<div class="content-wrapper"></div>');
  // Move the other boxes inside
  boxOne.appendTo(container.find(".content-wrapper"));
  boxTwo.appendTo(container.find(".content-wrapper"));
  // finally wrap the real container wrapper
  container.wrapInner('<div class="container-wrapper"></div>');
}

function adjustFooterContent() {
  // Referencing the footer boxes
  const quickie = $(".footerBox.FooterLinks");
  const helpful = $(".footerBox.FooterHelpful");
  const socials = $(".footerBox.FooterSocials");
  const contact = $(".footerBox.FooterContact");
  const regLogo = $(".footerBox.FooterLogos");
  const regText = $(".footerBox.FooterRegistered");
  // Wrap the elements with a div element
  quickie.wrapInner('<div class="quickie-wrapper"></div>');
  socials.wrapInner('<div class="socials-wrapper"></div>');
  helpful.wrapInner('<div class="helpful-wrapper"></div>');
  contact.wrapInner('<div class="contact-wrapper"></div>');
  regLogo.wrapInner('<div class="reglogo-wrapper"></div>');
  regText.wrapInner('<div class="regtext-wrapper"></div>');
  // Move all footer boxes correctly through
  contact.appendTo(socials);
  regText.appendTo(regLogo);
  helpful.appendTo(quickie);
  socials.appendTo(quickie);
  regLogo.appendTo(quickie);
}

function adjustFooterNewsletter() {
  // Referencing the newsletter boxes
  const banner = $(".footerBox.NewsletterBanner");
  const tagline = $(".footerBox.NewsletterTagline");
  const subscribe = $(".footerBox.NewsletterSign-up");
  // Wrap the elements with a div element
  banner.wrapInner('<div class="image-wrapper"></div>');
  tagline.wrapInner('<div class="tagline-wrapper"></div>');
  subscribe.wrapInner('<div class="subscribe-wrapper"></div>');
  // Wrap new content wrapper after the image
  banner.append('<div class="content-wrapper"></div>');
  // Move the other two footer boxes inside
  tagline.appendTo(banner.find(".content-wrapper"));
  subscribe.appendTo(banner.find(".content-wrapper"));
}

function initCarousel(feed, ctaText, ctaURL, slideText, optFade = false, optShow = 2, optAuto = true) {
  // Set the carousel selector
  let carousel = ".homeFeed." + feed;
  // If carousl exists on page
  if ($(`${carousel}`).length) {
    // Adjust the feed details first
    adjustFeedDetails(carousel);
    // Create the slider arrows + dots + cta
    let elements = createSliderElements(ctaText, ctaURL, slideText);
    // Then append under the carousel
    $(`${carousel}`).append(elements.navigator);
    // Init slick slider
    $(`${carousel} .feedList`).slick({
      dots: true,
      arrows: true,
      appendDots: $(`${carousel} .dot-wrapper`),
      prevArrow: $(`${carousel} .prev-slide`),
      nextArrow: $(`${carousel} .next-slide`),
      fade: optFade,
      infinite: true,
      slidesToShow: optShow,
      slidesToScroll: 1,
      autoplay: optAuto,
      autoplaySpeed: 4000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  }
}

function adjustFeedDetails(carousel) {
  // for each feed item present
  $(`${carousel} .feedItem`).each(
    function () {
      // Set reference to both footer and details wrapper of feed
      let footerWrapper = $(this).find(".postFooterWrapper");
      let detailsWrapper = $(this).find(".feedItemDetailsWrapper");
      // Move the footer wrapper to the details wrapper
      footerWrapper.prependTo(detailsWrapper);
    }
  );
}

function createSliderElements(ctaText, ctaURL, scrollText) {
  let iconPrev = '<i class="fa fa-chevron-left" aria-hidden="true"></i>';
  let iconNext = '<i class="fa fa-chevron-right" aria-hidden="true"></i>';
  let prevArrow = '<button type="button" aria-label="Previous slide" class="prev-slide">' + iconPrev + '</button>';
  let nextArrow = '<button type="button" aria-label="Next slide" class="next-slide">' + iconNext + '</button>';
  let dotCustom = '<div class="dot-wrapper"></div>';
  let sliderNav = '<div class="slider-nav">' + prevArrow + dotCustom + nextArrow + "</div>";
  let slideMore = '<p class="slider-label">' + scrollText + '</p>';
  let sliderBox = '<div class="slider-box">' + slideMore + sliderNav + "</div>";
  let ctaButton = '<a class="button" href="' + ctaURL + '" aria-label="' + ctaText + '">' + ctaText + "</a>";
  let navigator = '<div class="navigator">' + sliderBox + ctaButton + "</div>";
  return {
    prevArrow,
    nextArrow,
    dotCustom,
    sliderNav,
    slideMore,
    sliderBox,
    navigator,
  };
}
